.m48,
.m52__single,
.m53__single,
.m69__single
  color: $black

  a 
    color: inherit
    transition: color .3s ease
    text-decoration: underline

    &:hover
      color: $darkGreen

  h1, h2
    margin-bottom: 0

  h1
    color: $blue

  h2
    font-family: inherit
    font-weight: 600
    font-size: 18px

    @include mobile
      font-size: 16px

  .m48__navigation,
  .m52__navigation,
  .m53__navigation,
  .m69__navigation
    margin-bottom: 2rem

    a
      text-decoration: none

    svg
      margin-right: .5rem
      transform: scaleX(-1)

  .wysiwyg,
  .image-group,
  .image-groups
    max-width: 860px

  .source
    margin: .75rem 0 2.5rem
    display: flex
    flex-wrap: wrap
    gap: .375rem 1.1rem

    @include desktop
      margin: .5rem 0 3.5rem
      gap: .5rem 1.5rem

    & > *:first-child
      flex-basis: 100%
  
      @include desktop
        flex-basis: auto

  .m48__video
    margin-top: .5rem
    
  .m48__contact_lead
    margin-bottom: .5rem !important

  .m48__contact_details
    display: grid
    grid-template-columns: auto 1fr
    gap: .5rem
    align-items: center
    font-size: 1rem
    margin-bottom: 1.5rem

    img
      max-width: 1rem
      height: auto
      display: block
      margin: 0