.m69__single
  .m69__content
    grid-column: span 12

    @include lbg_tablet
      grid-column: 1 / span 7

    @include lbg_desktop
      grid-column: 1 / span 7

  .m69__links-attachments
    grid-column: span 12
    margin-top: 1.5rem
    display: flex
    flex-direction: column
    gap: 4px

    @include lbg_tablet
      grid-column: 9 / span 4

    @include lbg_desktop
      grid-column: 9 / span 3

    .lbg__sidebar-links.m69__attachments a
      grid-template-columns: 1fr auto
      
      span:nth-child(3)
        display: none