.m49,
.m52,
.m53,
.m57,
.m69
  .projects,
  .material,
  .practices
    grid-column: span 12

    @include lbg_tablet
      grid-column: 1 / span 7

    @include lbg_desktop
      grid-column: 2 / span 6

    ul li:nth-child(n+4)
      display: none

    ul.showAll 
      & + .toggleAll
        display: none !important

      li
        display: block !important

    .toggleAll
      margin-top: 4rem
      cursor: pointer

  .reset.btn-primary--outline
    width: 100%

  .count-export
    display: flex
    justify-content: space-between

  .exportXLS
    color: inherit
    text-align: center
    text-decoration: underline
    cursor: pointer
    transition: color .3s ease

    &:hover
      color: $green

  .contact-us
    margin-top: 1.5rem
    padding-top: 1rem
    border-top: 1px solid rgba($blue, .1)
    line-height: 1.3
    font-weight: 600

    a
      text-decoration: underline
      color: inherit
      transition: color .3s ease

      &:hover
        color: $green

  .project + .project,
  .practice + .practice,
  .material + .material
    border-top: 1px solid $blueLight
    margin-top: 2.5rem
    padding-top: 2.5rem

  .highlight
    color: $green
    font-weight: 500
    position: relative
    padding-left: 1rem
    margin-bottom: 1rem

    &::after,
    &::before
      content: ''
      display: block
      position: absolute
      left: 3px
      top: 50%
      z-index: 1
      width: 6px
      height: 6px
      margin-top: -3px
      border-radius: 50%
      background: $green

    &::before
      animation: m49-highlight 2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite

    &.highlight--m57
      color: $blue

      &::after,
      &::before
        background: $blue

  .project,
  .practices,
  .material
    color: $black
  
  h2
    font-family: 'Antonia H3'
    font-size: 22px
    line-height: em(26, 22)
    max-width: 500px

    @include mobile
      font-size: 18px
      line-height: em(24, 18)

    a
      display: block
      color: inherit
      transition: color .3s ease

      &:hover
        color: $green

  .material h2
    max-width: initial

  .source
    margin-top: 1rem
    line-height: 1.4
    display: flex
    gap: 1.5rem
    
    a
      word-break: break-word
      color: inherit
      transition: color .3s ease
      text-decoration: underline

      &:hover
        color: $darkGreen

       
.m57,
.m69
  .projects,
  .material
    @include lbg_desktop
      grid-column: 1 / span 7

.m48,
.m49,
.m52,
.m53,
.m52__single,
.m53__single,
.m57,
.m69,
.m69__single
  .groups
    display: grid
    grid-template-columns: 1fr 1fr 1.5fr
    gap: $gutter*2
  
  .image-group,
  .image-groups
    display: grid
    grid-template-columns: 1fr 1.25fr 1fr
    gap: $gutter
    margin-top: 1.5rem

  .image-group
    grid-template-columns: 1.25fr 2fr

  .image-tags
    display: grid
    grid-template-columns: 1fr
    gap: $gutter
    align-items: start
    margin-top: 1.5rem

    @include lbg_mobile
      grid-template-columns: 13.625rem 1fr

    h3:first-child
      margin-top: 0

  .image-group + .wysiwyg,
  .image-groups + .wysiwyg
    margin-top: 2rem

  .groups,
  .image-tags,
  .image-group,
  .image-groups
    h3
      color: $black
      font-weight: 500
      margin-top: 1rem
    
    dl
      margin-top: 1rem
      display: grid
      grid-template-columns: auto 1fr
    
    dt
      font-weight: 600
      padding-right: 1rem

    dt,
    dd
      padding-bottom: .5625rem
      margin-bottom: .5625rem
      border-bottom: 1px solid #eee

      &:last-of-type
        padding-bottom: 0
        margin-bottom: 0
        border-bottom: 0

  .image
    width: 100%
    padding-top: 70%
    position: relative

    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover

  .tags
    display: flex
    flex-wrap: wrap
    gap: .125rem
    margin-top: .5rem

    span
      color: #001141
      font-size: 12px
      line-height: 14px
      background: #dee7ff
      border-radius: 4px
      padding: 2px 5px

  @keyframes m49-highlight
    0%
      transform: scale(1)
    100%
      transform: scale(3)
      opacity: 0
