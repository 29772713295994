// shared styles for m60 & m61
.m60,
.m61
  .m60__portrait,
  .m61__portrait
    width: 100%
    padding-top: 130%
    height: auto
    position: relative

    img
      display: block
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      object-fit: cover
  
.m60
  width: calc( 100vw - var(--offset)*2 )
  margin-left: calc(-50vw + 50% + var(--offset))
  background: url('../images/bik-header.svg?v2') bottom right no-repeat
  background-color: var(--blue-light)
  background-size: contain

  &[data-site-id="36"]
    background: url('../images/commandos-header.svg') bottom right no-repeat
    background-color: var(--primary)
    background-size: contain
    
    .m60__name h1
      font-family: 'Antonia H1'
      
    .m60__name h1,
    .m60__name .m60__name_prefix,
    .m60__name .m60__name_suffix,
    .lbg__breadcrumbs--inline a
      color: var(--white)

    .lbg__breadcrumbs--inline svg path
      fill: var(--white)

  @media only screen and (min-width: 1200px) and (max-width: 1300px)
    position: relative
    left: -12px
    margin-left: 0

  .m60__profile
    display: flex
    display: grid
    grid-template-columns: 10.625rem 1fr
    gap: 3rem

  .m60__name
    color: $black

    span
      font-size: 1rem
      font-weight: 500

      &.m60__name_prefix
        color: #7e6e7e