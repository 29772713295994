.lbg-anker
  @media (hover: hover)
    .lbg-arrow
      &::before
        transform: translate(-.5rem) 

      &::after
        transform: translate(-.5rem, -50%) rotate(-45deg) 

    &:hover
      .lbg-arrow
        transform: translateX(.25rem)

        &::before
          transform: translate(0) 

        &::after
          transform: translate(0, -50%) rotate(-45deg) 