.m06 {
  width: 100%;

  &__section {
    background: url('../images/uber-uns.jpg') center center no-repeat;
    background-size: cover;
    padding: $m-vs 0;
  }

  &[data-site-id="29"] .m06__section {
    background: url('../images/bik-header.svg?v2') bottom right no-repeat;
    background-color: var(--blue-light);
    background-size: contain
  }

  &[data-site-id="36"] .m06__section {
    background: url('../images/commandos-header.svg') bottom right no-repeat;
    background-color: var(--primary);
    background-size: contain;
    
    .m06__title,
    .m06__content p {
      color: var(--white);
    }
  }

  &__section-inner {
    display: grid;
    gap: .75rem 5.5rem;
    grid-template-columns: 1.125fr .875fr;
    align-items: start;
    
    @include tablet {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @include mobile {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__title {
    grid-column: 1 / span 2;
    font-family: 'Antonia H1';
    font-size: 56px;
    line-height: em(60, 56);
    font-weight: 400;
    color: $black;

    @include tablet {
      font-size: 50px;
      line-height: em(50, 50);
      grid-column: 1 / span 1;
    }

    @include mobile {
      font-size: 40px;
      line-height: em(50, 40);
      grid-column: 1 / span 1;
    }
  }

  &__content {
    p {
      font-size: 24px;
      font-weight: 300;
      line-height: em(32, 24);
      color: $black;

      @include tablet {
        margin-bottom: 1.5rem;
      }

      @include mobile {
        font-size: 20px;
        line-height: em(28, 20);
        margin-bottom: 1.5rem;
      }
    }
  }

  &__label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: em(20, 14);
    letter-spacing: 0.1px;
    color: #001141;
    font-variant: small-caps;
    margin-bottom: .25rem;

    &:empty {
      display: none
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin-top: 0.375rem;

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin: .25rem 24px .5rem 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .link-secondary {
      text-underline-offset: 4px;
      white-space: nowrap;
      
      &:hover {
        color: $darkGreen;
      }
    }
  }

  &__select {
    width: 100%;
    max-width: 300px;
    position: relative;

    @include mobile {
      max-width: 100%;
    }
    @include tablet {
      margin-top: 32px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 20px;
      right: 16px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid $black;
      transition: $transition1;
    }
    select {
      width: 100%;
      padding: 13px 24px 15px;
      font-size: 14px;
      font-weight: 500;
      line-height: em(19, 14);
      color: $black;
      background-color: rgba($color: #fff, $alpha: 0.5);
      border-radius: 3px;
      border: none;
      outline: none;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;

      option {
        font-size: 14px;
        font-weight: 500;
        line-height: em(16, 14);
        color: $black;
        padding: 10px;
      }
    }
  }
}
