.m33 {
  width: 100%;
  position: relative;
  background: #f1f5ff;

  .section-title-small {
    margin-bottom: 0;
  }

  &:before {
    content: '';
    background: #f1f5ff;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: calc(-50vw + 635px);
    top: 0;
    z-index: -1;
    @include tablet-big {
      left: calc(-50vw + 485px);
    }
    @include tablet {
      left: calc(-50vw + 373px);
    }
    @include mobile {
      left: -24px;
    }
  }
  .sa {
    padding: 150px 0 60px;
    @include mobile {
      margin-left: -24px;
      background-color: #f1f5ff;
    }
    &-list {
      @include mobile {
        padding-left: 24px;
      }
      h2:first-child {
        font-family: 'Antonia H2';
        font-size: 36px;
        line-height: 46px;
        font-weight: 500;
        color: $blue;
        margin-bottom: 60px;
      }
    }
  }

  .section-title-small {
    font-size: 28px;
    line-height: em(32,28);
    font-weight: 500;
    @include mobile {
      margin-bottom: 16px !important;
      &:nth-child(2) {
        margin-top: 64px;
      }
    }
  }
}
