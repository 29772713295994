.m24 {
  width: 100%;
  background-color: #f1f5ff;

  .lbg-grid {
    row-gap: 2.5rem
  }
  
  &__person {
    width: 100%;
    display: flex;
    grid-column: span 12;

    @include lbg_tablet {
      grid-column: span 6;
    }
    
    @include lbg_desktop {
      grid-column: span 5;
    }
    
    &__image {
      width: 84px;
      height: 84px;
      border-radius: 100%;
      overflow: hidden;
      img {
        @extend %imgCropped;
      }
    }
    &__content {
      width: 300px;
      margin-left: 24px;
      @include mobile {
        width: calc(100% - 108px);
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        line-height: em(24, 20);
        color: $black;
        margin-bottom: 8px;
      }
      p {
        font-size: 17px;
        font-weight: 500;
        line-height: em(24, 17);
        color: $pinkDark;
      }
      a {
        display: block;
        font-size: 16px;
        line-height: em(24, 16);
      }
    }
  }
  .tags-area {
    margin-top: 0;
    li {
      margin-top: 8px;
    }
    a {
      display: block;
    }
  }
  &_extraLinks {
    width: 100%;
    margin-top: 3rem;

    h3 {
      font-family: 'Antonia H3';
      font-size: 22px;
      line-height: em(26, 22);
      color: $black;
      padding-bottom: .25rem;
      margin-bottom: 1.25rem;
      border-bottom: 1px solid $black;
    }
    li {
      width: 100%;
      margin-bottom: .5rem;
    }
    a {
      display: block;
      position: relative;
      font-size: 18px;
      line-height: em(21, 18);
      color: $blue;
      padding-left: 24px;
      &:hover {
        &:before {
          left: 3px;
        }
      }
      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 7px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 8px solid $blue;
        transition: $transition1;
      }
    }
  }
}
